<template>
	<div class="device">
		<div class="top">
			<img :src="contData.img" />
			<div class="title_Box animated fadeIn" :class="[isshow?header:'']">
				<h2>{{$t(contData.title)}}</h2>
				<p>{{contData.desc}}</p>
			</div>
			<div class="tab_bg"></div>
			<ul class="active">
				<li class="animated fadeInUp" @click="active(0)" :class="[num==0?'bgColor':'']">DI EQUIPMENT</li>
				<li class="animated fadeInUp" @click="active(1)" :class="[num==1?'bgColor':'']">SOUND EQUIPMENT</li>
				<li class="animated fadeInUp" @click="active(2)" :class="[num==2?'bgColor':'']">MUSIC RECORDING</li>
			</ul>
		</div>
		<div class="text_cont" :class="[isshow?text:'']">
			<h2 v-for="item of $t(contData.text1)" :key="item" :class="$t(isFont)=='true'?'font_cn':''">{{item}}</h2>
			<p v-for="item of $t(contData.text2)" :key="item">{{item}}</p>
		</div>
		<div class="deviceImg">
			<HlySwipe :list="contData.list" v-if="contData" />
		</div>
		<ul>
			<li :class="[isshow?menu:'']" v-for="(item,index) in contData.more" :key="index">{{item}}</li>
		</ul>
	</div>
</template>
<script>
import HlySwipe from "@/components/HlySwipe.vue";
export default {
  name: "device",
  data() {
    return {
      num: 0,
      header: "animated fadeIn",
      text: "animated fadeInUp",
      menu: "animated fadeInLeft",
      contData: "",
      isshow: false,
      isFont:'lang.home.font',
      diData: {
        img: require("../assets/images/shebei/ID.png"),
        title: "lang.device.name1",
        desc: "Professional equipment",
        text1:"lang.device.text3",
        text2: [
          "Hanlinyuan puts the best tools and talent into the hands of storytellers. A name synonymous with color, our teams",
          "continue to push the boundaries of imaging- introducing and supporting unique pipelines and workflows and collaborating",
          "on the creation of stunning looks to bring all stories to life."
        ],
        more: [
          "Baselight X Top level Grading system",
          "Baselight Assist Grading system",
          "Christie DCI projection 2K,4K",
          "All system connect to Quantum Xcellis SAN",
          "COLORFRONT Transkoder",
          "DVS Clipster6"
        ],
        list: [
          { img: require("../assets/images/shebei/di_1.jpg") },
          { img: require("../assets/images/shebei/di_2.jpg") },
          { img: require("../assets/images/shebei/di_3.jpg") },
          { img: require("../assets/images/shebei/di_4.jpg") }
        ]
      },
      soundData: {
        img: require("../assets/images/shebei/sound.png"),
        title: "SOUND EQUIPMENT ",
        desc: "Professional equipment",
        text1: "lang.device.text21",
        text2: "lang.device.text22",
        more: [
          "AMS_NEVE - DFC Digital Film Console ",
          "AVID - S3 Controller ",
          "AVID - S6 Controller",
          "DOLBY ATMOS 7.1, 5.1 Mixing & Mastering",
          "AVID Protools Ultimate HDX System"
        ],
        list: [
          { img: require("../assets/images/shebei/sound_1.jpg") },
          { img: require("../assets/images/shebei/sound_2.jpg") },
          { img: require("../assets/images/shebei/sound_3.jpg") },
          { img: require("../assets/images/shebei/sound_4.jpg") }
        ]
      },
      musicData: {
        img: require("../assets/images/shebei/music.png"),
        title: "MUSIC RECORDING",
        desc: "Professional equipment",
        text1: "lang.device.text1",
        text2: "",
        more: [
          "AMS_NEVE - 88RS Console",
          "AMS_NEVE - Genesys Black G16 Console",
          "AMS_NEVE - Genesys Mixer",
          "AVID Protools Ultimate HDX System"
        ],
        list: [
          { img: require("../assets/images/shebei/music_1.jpg") },
          { img: require("../assets/images/shebei/music_2.jpg") },
          { img: require("../assets/images/shebei/music_3.jpg") }
        ]
      }
    };
  },
  components: {
    HlySwipe
  },
  watch: {
    contData(newVal, oldVal) {
      let that = this;
      this.isshow = true;
      setTimeout(function() {
        that.isshow = false;
      }, 500);
    }
  },
  mounted() {
    let type = this.$route.query.type;
    this.active(type);
  },
  methods: {
    active(index) {
      this.num = index;
      if (this.num == 0) {
        this.contData = this.diData;
      }
      if (this.num == 1) {
        this.contData = this.soundData;
      }
      if (this.num == 2) {
        this.contData = this.musicData;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.device {
  background: #061319;
  .top {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 40.625%;
    > img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .title_Box {
      position: absolute;
      left: 300px;
      top: 50%;
      transform: translate(0, -50%);
      text-align: left;
      font-family: "OpenSansRegular";
      > h2 {
        font-size: 50px;
        line-height: 1;
        margin-bottom: 20px;
        font-weight: normal;
      }
      > p {
        font-size: 20px;
        line-height: 1;
      }
    }
    .tab_bg {
      width: 100%;
      height: 190px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    > ul {
      width: 100%;
      height: 190px;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      > li {
        width: 33.33%;
        line-height: 190px;
        border-right: 1px solid rgba(250, 250, 250, 0.2);
        border-top: 1px solid rgba(250, 250, 250, 0.2);
        font-size: 22px;
        font-family: "OpenSansRegular";
        cursor: pointer;
      }
      > li:hover {
        background: rgba(0, 0, 0, 0.2);
      }
      .bgColor {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .text_cont {
    width: 100%;
    padding: 120px 17.7vw 0;
    text-align: left;
    > h2 {
      font-size: 24px;
      color: #fff;
      line-height: 40px;
      font-family: "OpenSansLight";
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
    .font_cn{
      font-weight: normal;
    }
    > p {
      font-size: 18px;
      color: #fff;
      line-height: 30px;
      font-family: "OpenSansLight";
    }
  }
  > ul {
    width: 100%;
    padding: 0 18.4vw 115px;
    margin-top: -120px;
    > li {
      list-style: disc;
      text-align: left;
      font-size: 18px;
      line-height: 46px;
      font-family: "OpenSansLight";
    }
  }
}
</style>